import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getTokenForBrightspeedAPI } from "../../../../shared/hooks/useFetchLogin";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";

type brightspeedAPITokenInitialStateType = {
  data: any;
  loading: boolean;
  error: any;
};
const initialState: brightspeedAPITokenInitialStateType = {
  data: null,
  loading: false,
  error: null,
};

export const getBrightspeedToken = createAsyncThunk(
  "brightspeedAPI/token",
  async () => {
    try {
      const params = {
        "": [API_URLS?.brightspeedGetTokenAPI],
      };
      const headers = {};
      const response = await getTokenForBrightspeedAPI(params, {}, headers);
      if (response?.data?.expires_in) {
        const time = new Date();
        time.setSeconds(
          time.getSeconds() + (parseInt(response.data.expires_in) - 99)
        );
        response.data.expires_in = time;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const brightspeedTokenSlice = createSlice({
  name: "brightspeed/token",
  initialState,
  reducers: {
    clearPlanDetailSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrightspeedToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrightspeedToken.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getBrightspeedToken.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "get token APi failed",
        action.error?.message,
        action?.type
      );
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearPlanDetailSlice } = brightspeedTokenSlice.actions;
export default brightspeedTokenSlice.reducer;
