import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getRepairAppointment } from "../../../../shared/hooks/useFetchUpcomingAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import {
  checkIsPastDate,
  checkIsUpcommingAppointment,
} from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type pastRepairAppointmentInitialStateType = {
  pastRepairAppointment: any;
  loading: boolean;
  error: any;
};
const initialState: pastRepairAppointmentInitialStateType = {
  pastRepairAppointment: null,
  loading: false,
  error: null,
};

export const getPastRepairAppointmentDetails = createAsyncThunk(
  "pastRepairAppointment/details",
  async (billingAccountId: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await getRepairAppointment(
        {
          "": `${API_URLS?.repairAppointmentURL}${billingAccountId}`,
          // "": `${API_URLS?.repairAppointmentURL}${"200005647"}`,
        },
        headers
      );

      const respData = response?.data?.result?.filter((anOrder: any) => {
        if (
          checkIsPastDate(anOrder?.u_appointment_start) ||
          anOrder?.u_dispatch_status === "Canceled" ||
          anOrder.u_dispatch_status === "Completed" ||
          anOrder.u_dispatch_status === "Cannot Complete"
        ) {
          return anOrder;
        }
      });

      return {
        result: respData,
      };
    } catch (error) {
      throw error;
    }
  }
);

const pastRepairAppointmentDetailSlice = createSlice({
  name: "pastRepairAppointment/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPastRepairAppointmentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPastRepairAppointmentDetails.fulfilled,
      (state, action) => {
        state.pastRepairAppointment = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getPastRepairAppointmentDetails.rejected,
      (state, action) => {
        errorConditionGTM(
          "BRSPD_Fiber_EC_Flow",
          "burriedDrop details",
          action.error?.message,
          action?.type
        );
        state.pastRepairAppointment = null;
        state.loading = false;
        state.error = action.error;
      }
    );
  },
});

export default pastRepairAppointmentDetailSlice.reducer;
