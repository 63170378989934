//Its' Making issue in Web App side

//import Toast from 'react-native-simple-toast';
//import { Alert } from 'react-native';
// import {localize} from '../locales';
// import {ENV_NAME} from 'react-native-dotenv';

//Its' Making issue in Web App side
// import NetInfo from '@react-native-community/netinfo';

// //import { log } from './LogUtils';

// const showAlert = message => {
//   Alert.alert(
//     // localize('app_name'),
//     'BrightSpeed',
//     message,
//     [
//       {
//         // text: localize('btn_ok'),
//         text: 'OK',
//         onPress: () => {
//           log('OK Pressed');
//         },
//       },
//     ],
//     { cancelable: false },
//   );
// };

// const showToast = message => {
//   return Toast.show(message, Toast.LONG);
// };

import { notificationType } from "../../shared/utils/Constants";

const isAppEnv = () => {
  return !!!window.location;
};

const isInternetConnected = async () => {
  // let state = await NetInfo.fetch();
  // return state.isConnected;
  return true;
};
const replacePlaceholders = (str, values) => {
  let modifiedString = str;
  values.forEach((value, index) => {
    const placeholder = new RegExp(`{{.+?}}`);
    modifiedString = modifiedString.replace(placeholder, value);
  });
  return modifiedString;
};
const generateNotificationId = (type, accountId, ccLastDigits) => {
  if (type == notificationType.creditCard) {
    return accountId + ccLastDigits;
  }
  if (type === "VacationSuspend") {
    return accountId + "VacationSuspend";
  }
  if (type === "ACPBanner") {
    return accountId + "ACPBanner";
  }
  if (type === "RoadBore") {
    return accountId + "RoadBore";
  }
  if (type === "BuryCable") {
    return accountId + "buried";
  }
};
export {
  isInternetConnected,
  isAppEnv,
  replacePlaceholders,
  generateNotificationId,
};
