import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { addressQualification } from "../../../../shared/hooks/useFetchCompleteAddress";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type addressQualifucationInitialStateType = {
  data: any;
  loading: boolean;
  error: any;
  networkType: any;
};
const initialState: addressQualifucationInitialStateType = {
  data: null,
  loading: false,
  error: null,
  networkType: null,
};

export const getAddressQualification = createAsyncThunk(
  "get/address/qualification",
  async (user: any) => {
    try {
      const serviceAdd = user?.addresses?.find(
        (addr: any) => addr?.addressType === "ServiceAddress"
      );
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      if (
        serviceAdd &&
        serviceAdd?.street &&
        serviceAdd?.city &&
        serviceAdd?.state &&
        serviceAdd?.addressId
      ) {
        const userAddress = encodeURIComponent(
          `${serviceAdd?.street?.trim()} ${serviceAdd?.city?.trim()}, ${serviceAdd?.state?.trim()}, ${
            serviceAdd?.zip
          }`
        );
        const addressQualificationRes = await addressQualification(
          {
            "": `${API_URLS?.addressQualificationURL}?addressLine1=${userAddress}&existingUser=EC`,
          },
          headers
        );
        if (
          addressQualificationRes?.status === 200 &&
          addressQualificationRes?.data
        ) {
          return {
            addressQualification: addressQualificationRes?.data,
          };
        } else {
          throw new Error("Something went wrong with address qualification");
        }
      } else {
        throw new Error("Invalid user service address");
      }
    } catch (error) {
      throw error;
    }
  }
);

const addressQualificationSlice = createSlice({
  name: "address/qualification",
  initialState,
  reducers: {
    clearAddressQualificationState: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressQualification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAddressQualification.fulfilled, (state, action) => {
      state.data = action.payload;
      state.networkType = action.payload?.addressQualification?.entity?.networkType;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAddressQualification.rejected, (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearAddressQualificationState } =
  addressQualificationSlice.actions;
export default addressQualificationSlice.reducer;
