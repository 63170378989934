import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1366,
    },
  },
  palette: {
    primary: {
      main: "#ffc800",
      light: "#fff",
    },
    secondary: {
      main: "#f2f3f3",
    },
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            textTransform: "none",
            backgroundColor: "#ffc800",
            borderRadius: "25px",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            minWidth: "200px",
            fontSize: "1rem",
            margin: "0.8rem",
            "&:hover": {
              backgroundColor: "#ffc800",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            textTransform: "none",
            backgroundColor: "#fff",
            borderRadius: "25px",
            border: "2px solid #000",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            minWidth: "200px",
            fontSize: "1rem",
            margin: "0.8rem",
            "&:hover": {
              border: "2px solid #000",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            textTransform: "none",
            backgroundColor: "#d8d8d8",
            borderRadius: "25px",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            fontSize: "1rem",
            width: "100%",
            margin: "0.8rem",
          },
        },
      ],
    },
  },
});

export default theme;
