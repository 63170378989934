import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPaymentMethodDetails } from "../../../../shared/hooks/useFetchPayment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type singlePaymentMethodsDetailsInitialStateType = {
  singlePaymentMethodsDetails: any;
  loading: boolean;
  error: any;
};

const initialState: singlePaymentMethodsDetailsInitialStateType = {
  singlePaymentMethodsDetails: null,
  loading: false,
  error: null,
};

export const getSinglePaymentMethodDetails = createAsyncThunk(
  "single/paymentMethod/details",
  async (cardId: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await fetchPaymentMethodDetails(
        {
          [API_URLS?.addUpdatePaymentMethodUrl_QA]: cardId,
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const singlePaymentMethodDetailSlice = createSlice({
  name: "single/paymentMethod/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSinglePaymentMethodDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSinglePaymentMethodDetails.fulfilled,
      (state, action) => {
        state.singlePaymentMethodsDetails = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getSinglePaymentMethodDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment method by id",
        action.error?.message,
        action?.type
      );
      state.singlePaymentMethodsDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default singlePaymentMethodDetailSlice.reducer;
