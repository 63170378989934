import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchCurrentPlan } from "../../../../shared/hooks/useFetchCurrentPlan";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type nextPaymentDetailsInitialStateType = {
  nextPaymentDetails: any;
  loading: boolean;
  error: any;
};
const initialState: nextPaymentDetailsInitialStateType = {
  nextPaymentDetails: null,
  loading: false,
  error: null,
};

export const getNextPaymentDetails = createAsyncThunk(
  "nextPayment/details",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const params = {
        [API_URLS?.upcomingPayment_QA_Url]: billingAccountNumber,
      };
      const response = await fetchCurrentPlan(params, headers);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const nextPaymentDetailSlice = createSlice({
  name: "nextPayment/Details",
  initialState,
  reducers: {
    clearNextPaymentDetailSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNextPaymentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNextPaymentDetails.fulfilled, (state, action) => {
      state.nextPaymentDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getNextPaymentDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "next payment",
        action.error?.message,
        action?.type
      );
      state.nextPaymentDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearNextPaymentDetailSlice } = nextPaymentDetailSlice.actions;
export default nextPaymentDetailSlice.reducer;
