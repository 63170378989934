import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getRepairAppointment } from "../../../../shared/hooks/useFetchUpcomingAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { checkIsUpcommingAppointment } from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type repairAppointmentInitialStateType = {
  repairAppointmentDetails: any;
  loading: boolean;
  error: any;
};
const initialState: repairAppointmentInitialStateType = {
  repairAppointmentDetails: null,
  loading: false,
  error: null,
};

export const getRepairAppointmentDetails = createAsyncThunk(
  "repairAppointment/details",
  async (billingAccountId: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await getRepairAppointment(
        {
          "": `${API_URLS?.repairAppointmentURL}${billingAccountId}`,
          // "": `${API_URLS?.repairAppointmentURL}${"200005647"}`,
        },
        headers
      );

      const respData = response?.data?.result?.filter((anOrder: any) => {
        if (
          checkIsUpcommingAppointment(anOrder?.u_appointment_start) &&
          anOrder?.u_dispatch_status?.toUpperCase() !== "CANCELED"
        ) {
          return anOrder;
        }
      });

      return {
        result: respData,
      };
    } catch (error) {
      throw error;
    }
  }
);

const repairAppointmentDetailSlice = createSlice({
  name: "repairAppointment/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRepairAppointmentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRepairAppointmentDetails.fulfilled, (state, action) => {
      state.repairAppointmentDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getRepairAppointmentDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "burriedDrop details",
        action.error?.message,
        action?.type
      );
      state.repairAppointmentDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default repairAppointmentDetailSlice.reducer;
