const initGTM = () => {
  window.dataLayer = window.dataLayer || [];
};

const onPageLoadGTM = (pageName, step, sessionId, billingAccountNumber) => {
  window.dataLayer.push({
    page: {
      pageType: "BRSPD_Fiber_Account_Management",
      pageName: pageName || "",
    },
    flow: {
      name: "BRSPD_Fiber_EC_Flow",
      step: step || undefined,
    },
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const backButtonClickedGTM = (name, step,sessionId, billingAccountNumber) => {
  window.dataLayer.push({
    event: "back",
    flow: {
      name: name,
      step: step,
    },
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const errorConditionGTM = (name, step, errorCode, errorName,sessionId, billingAccountNumber) => {
  window.dataLayer.push({
    event: "error",
    flow: {
      name: name,
      step: step,
    },
    errorCode: errorCode,
    errorName: errorName,
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const loginGTM = (eventName,username) => {
  window.dataLayer.push({
    event: eventName,
  //event: "digital_login_acct_mgmt",
    user: username,
    channel: "digital",
  });
};

const forgotPasswordGTM = () => {
  window.dataLayer.push({
    event: "digital_forgot_password_acct_mgmt",
    channel: "digital",
  });
};

const homeGTM = () => {
  window.dataLayer.push({
    event: "digital_home_acct_mgmt",
    channel: "digital",
  });
};

// const accountSettingGTM = () => {
//   window.dataLayer.push({
//     event: "digital_account-setting_acct_mgmt",
//     channel: "digital",
//   });
// };

// const viewPaymentsGTM = () => {
//   window.dataLayer.push({
//     event: "digital_viewPayments_acct_mgmt",
//     channel: "digital",
//   });
// };

// const paymentMethodsGTM = () => {
//   window.dataLayer.push({
//     event: "digital_paymentMethods_mgmt",
//     channel: "digital",
//   });
// };

// const appointmentGTM = () => {
//   window.dataLayer.push({
//     event: "digital_appointment_mgmt",
//     channel: "digital",
//   });
// };


// const helpandsupportGTM = () => {
//   window.dataLayer.push({
//     event: "digital_help-and-support_mgmt",
//     channel: "digital",
//   });
// };


const sideMenuClickGTM = (eventName) => {
  window.dataLayer.push({
    event: eventName,
    channel: "digital",
  });
};

const forgotUserNameGTM = () => {
  window.dataLayer.push({
    event: "digital_forgot_username_acct_mgmt",
    channel: "digital",
  });
};

const signupGTM = () => {
  window.dataLayer.push({
    event: "digital_create_account_acct_mgmt",
    channel: "digital",
  });
};

const logoutGTM = () => {
  window.dataLayer.push({
    event: "digital_logout_acct_mgmt",
    channel: "digital",
  });
};


const addEneventGTM = (eventName,sessionId, billingAccountNumber) => {
  window.dataLayer.push({
    event: eventName || undefined,
    channel: "digital",
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
  });
};

const uniLogineventGTM = (loginMethod,loginValue) => {
  window.dataLayer.push({
    event: "uni-login-account-management",
    pageType: "BRSPD_Fiber_Account_Management",
    channel: "digital",
    loginMethod: loginMethod,
    loginValue: loginValue,
  })
}

export {
  onPageLoadGTM,
  backButtonClickedGTM,
  errorConditionGTM,
  initGTM,
  loginGTM,
  forgotPasswordGTM,
  forgotUserNameGTM,
  homeGTM,
  signupGTM,
  addEneventGTM,
  //paymentMethodsGTM,
  //helpandsupportGTM,
  //appointmentGTM,
  //accountSettingGTM,
  //viewPaymentsGTM,
  sideMenuClickGTM,
  uniLogineventGTM,
  logoutGTM
};
