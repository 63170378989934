import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

type notificationInitialStateType = {
  notificationViewSortedData: any[];
  notificationArr: any[];
};
const initialState: notificationInitialStateType = {
  notificationViewSortedData: [],
  notificationArr: [],
};

const notificationSlice = createSlice({
  name: "notification/Details",
  initialState,
  reducers: {
    setNotificationModelSortedData: (state, action) => {
      state.notificationViewSortedData = action.payload;
    },
    updateNotificationModelSortedData: (state, action) => {
      //state.notificationViewSortedData = action.payload;
      console.log("state in notifdetais-->", state, action);
    },
    setNotification: (state, action) => {
      state.notificationArr.push(action.payload);
    },
    clearNotification: (state) => {
      state.notificationArr = [];
      state.notificationViewSortedData = [];
    },
  },
});

export const {
  setNotificationModelSortedData,
  updateNotificationModelSortedData,
  setNotification,
  clearNotification,
} = notificationSlice.actions;
export default notificationSlice.reducer;
