import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";

const PrivateRoutes = () => {
  const token = useAppSelector((state) => state.user.token);

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
