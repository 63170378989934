import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";
import {
  SMARTY_STREET_ADDRESS_URL,
  LOCALHOST_SMARTY_STREET_ADDRESS_URL,
  VALIDATE_ADDRESSS_GET_LAT_LOG,
  AMS_Cridentials,
} from "../utils/Constants";

const fetchCompleteAddress = async (
  searchStreetAddress = "",
  selected = ""
) => {
  const env = getEnvironment();
  //dev-server-auth url not responding
  // dev-server-auth info We will use SMARTY_STREET_ADDRESS_URL
  const SERVER_URL =
    SMARTY_STREET_ADDRESS_URL +
    searchStreetAddress +
    "&source=all" +
    `&selected=${selected}`;

  // local authid info only for localhost we will use LOCALHOST_SMARTY_STREET_ADDRESS_URL
  const LOCAL_URL =
    LOCALHOST_SMARTY_STREET_ADDRESS_URL +
    searchStreetAddress +
    "&source=all" +
    `&selected=${selected}`;

  // process.env.REACT_APP_ENV will be available only in web app
  return await ApiService.get(
    { apiUrl: process.env.REACT_APP_ENV ? SERVER_URL : LOCAL_URL },
    {},
    {}
  );
};

export const validateAddressAndGetLatLong = async (
  params = {},
  headers = {}
) => {
  return await ApiService.get(
    {
      ...params,
      apiUrl: VALIDATE_ADDRESSS_GET_LAT_LOG,
    },
    { headers: headers }
  );
};

export const get_AMS_API_token = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  const credentials =
    AMS_Cridentials[process.env.REACT_APP_ENV] ||
    AMS_Cridentials[process.env.NODE_ENV] ||
    AMS_Cridentials.production;
  return await ApiService.post(
    {
      ...params,
      apiUrl: env?.ams_token_url,
    },
    {},
    {
      headers: {
        ...headers,
        Authorization: `Basic ${window.btoa(
          `${credentials.amsAuthCredentitals.username}:${credentials.amsAuthCredentitals.password}`
        )}`,
      },
    }
  );
};

export const AMS_Lookup = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    {
      ...params,
      apiUrl: env?.ams_base_url,
    },
    {
      headers: headers,
    }
  );
};

export const addressQualification = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    {
      ...params,
      apiUrl: env?.brightspeedApi,
    },
    { headers: headers }
  );
};

export const unifiedLoginWithMobileNumber = async (
  params = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.get(
    {
      ...params,
      apiUrl: env?.brightspeedApi,
    },
    { headers: headers }
  );
};

export const unifiedLoginWithEmailAddress = async (
  params = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.get(
    {
      ...params,
      apiUrl: env?.brightspeedApi,
    },
    { headers: headers }
  );
};

export default fetchCompleteAddress;
