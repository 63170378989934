import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const useFetchServiceDetails = (params = {},header={}) => {
  const [data, setData] = useState(null);
  let isMountedScreen = true;
  useEffect(() => {
    getResponse();
    return () => {
      isMountedScreen = false;
    };
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get({ ...params, apiUrl: env.liveUrl }, { headers: header }).then(
      (result) => {
        if (isMountedScreen) {
          setData(result.data);
        }
      }
    );
  };
  const reload = async () => {
    await getResponse();
  };
  return [data, reload];
};

// return promise
export const fetchServiceDetails = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

// return promise
export const fetchServiceDetailsLatestAPI = async (
  params = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

// return promise for plan details
export const fetchPlanDetails = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const useFetchPlanDetails = (params = {},header={}) => {
  const [data, setData] = useState(null);
  let isMountedScreen = true;
  useEffect(() => {
    getResponse();
    return () => {
      isMountedScreen = false;
    };
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    if(!JSON.stringify(params).includes("null")){
    ApiService.get({ ...params, apiUrl: env.brightspeedApi }, {headers:header}).then(
      (result) => {
        if (isMountedScreen) {
          setData(result.data);
        }
      }
    );
    }
  };
  const reload = async () => {
    await getResponse();
  };
  return [data, reload];
};

export default useFetchServiceDetails;
