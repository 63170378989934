import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getBurriedDrop } from "../../../../shared/hooks/useFetchBurriedDrop";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type burriedDropInitialStateType = {
  burriedDropDetails: any;
  loading: boolean;
  error: any;
};
const initialState: burriedDropInitialStateType = {
  burriedDropDetails: null,
  loading: false,
  error: null,
};

export const getBurriedDropDetails = createAsyncThunk(
  "burriedDrop/details",
  async (accountId: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await getBurriedDrop(
        {
          "": `${API_URLS?.buryCableMsgUrl}?addressId=${accountId}`,
        },
        headers
      );
      const checkOrderNumber =
        reduxStore?.upComingAppointment?.upcomingAppointment
          ?.upcomingAppointments[0]?.orderDetails?.pendingOrderDetails[0]
          ?.orderNumber;

      if (checkOrderNumber) {
        response.data =
          response?.data?.find((item: any) => {
            if (item?.bswOrderNumber === checkOrderNumber) {
              return item;
            }
          }) || null;
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const burriedDropDetailSlice = createSlice({
  name: "burriedDrop/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBurriedDropDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBurriedDropDetails.fulfilled, (state, action) => {
      state.burriedDropDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getBurriedDropDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "burriedDrop details",
        action.error?.message,
        action?.type
      );
      state.burriedDropDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default burriedDropDetailSlice.reducer;
