import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchAccountInfo from "../../../../shared/hooks/useFetchAccountInfo";
import { generateSessionId } from "../../../../shared/hooks/useFetchLogin";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type userInitialStateType = {
  acp: string;
  acpModalStatus: boolean;
  data: any;
  loading: boolean;
  error: any;
  token: string;
  jwtToken: string;
  refreshToken: string;
  rootToken: string;
  clientId: string;
  changePassClientId: string;
  principalName: string;
  accountEnabled: string;
  companyName: string;
  otp: string;
  forgotPassPrefMethod: string;
  canUserResetPass: boolean;
  emailOnlyForRegister: string;
  accountIdOnlyForRegister: string;
  sessionId: string;
  contactNumber: string;
  isCaptchaChecked: boolean;
  isCaptchaLoading: boolean;
  forgotEmailId: string;
  appDownloadModalHideAsDoNotShowAgain: boolean;
  reOpenAppDownloadModal: boolean;
  emailBeforeLogin: string;
  isUserSettingPassFromEmailLink: boolean;
  isSilentLogin: boolean;
  originalPrimaryEmail: string;
};

const initialState: userInitialStateType = {
  acp: "no",
  acpModalStatus: false,
  data: null,
  loading: false,
  error: null,
  token: localStorage?.getItem("token") || "",
  jwtToken: "",
  refreshToken: "",
  rootToken: localStorage?.getItem("rootToken") || "",
  clientId: "",
  changePassClientId: "",
  principalName: "",
  accountEnabled: "",
  companyName: "",
  otp: "",
  forgotPassPrefMethod: "",
  canUserResetPass: false,
  emailOnlyForRegister: "",
  accountIdOnlyForRegister: "",
  sessionId: localStorage?.getItem("sessionId") || "",
  contactNumber: "",
  isCaptchaChecked: false,
  isCaptchaLoading: false,
  forgotEmailId: "",
  appDownloadModalHideAsDoNotShowAgain: localStorage.getItem(
    "appDownloadModalHideAsDoNotShowAgain"
  )
    ? true
    : false,
  reOpenAppDownloadModal: false,
  emailBeforeLogin: "",
  isUserSettingPassFromEmailLink: false,
  isSilentLogin: false,
  originalPrimaryEmail: localStorage.getItem("originalPrimaryEmail") || "",
};

export const getUserDetails = createAsyncThunk(
  "user/Details",
  async (email: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "",
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await fetchAccountInfo(
        {
          [API_URLS?.accountManagementUrl]: `account-details-user?username=${email.toLowerCase()}`,
        },
        headers
      );

      // for jeopardy status will be is Past payment due
      // response.data.currentStatus = "Past payment due";
      // response.data.currentStatus = "Suspended";
      // response.data.suspendReason = "Payment Failed";

      if (
        response?.data?.suspendReason === "Payment Failed" &&
        response?.data?.currentStatus !== "Active"
      ) {
        response.data.isSuspended = true;
      }

      if (response?.data?.email) {
        response.data.suspendStartDate =
          response?.data?.suspendStartDate?.trim() || "";
        response.data.suspendEndDate =
          response?.data?.suspendEndDate?.trim() || "";
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSessionId = createAsyncThunk("user/sessionId", async () => {
  try {
    const reduxStore = store.getState();
    const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
    const headers = {
      Authorization:
        process.env.REACT_APP_ENV === "production"
          ? `Bearer ${globalToken}`
          : "",
      brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
    };
    const response = await generateSessionId({}, headers);
    localStorage?.setItem("sessionId", response.data?.sessionId);
    return response.data.sessionId;
  } catch (error) {
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAcpStatus: (state, action) => {
      state.acp = action.payload.toLowerCase();
      return state;
    },
    setAcpModalStatus: (state, action) => {
      state.acpModalStatus = action.payload;
      return state;
    },
    addToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    addRootToken: (state, action) => {
      state.rootToken = action.payload;
      return state;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("originalPrimaryEmail");
      state.data = null;
      state.error = null;
      state.loading = false;
      state.token = "";
      state.originalPrimaryEmail = "";
      localStorage.removeItem("changePending");
    },
    addClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
    removeClientId: (state) => {
      state.clientId = "";
    },
    addOTP: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
    },
    setCanUserResetPass: (state, action: PayloadAction<boolean>) => {
      state.canUserResetPass = action.payload;
    },
    setPrefMethod: (state, action: PayloadAction<string>) => {
      state.forgotPassPrefMethod = action.payload;
    },
    setContactNumber: (state, action: PayloadAction<string>) => {
      state.contactNumber = action.payload;
    },
    addChangePassClientId: (state, action: PayloadAction<string>) => {
      state.changePassClientId = action.payload;
    },
    removeChangePassClientId: (state) => {
      state.changePassClientId = "";
    },
    addPrincipalName: (state, action: PayloadAction<string>) => {
      state.principalName = action.payload;
    },
    addFirstTimeSetPasswordAccountEnabled: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accountEnabled = action.payload;
    },
    addCompanyName: (state, action: PayloadAction<string>) => {
      localStorage?.setItem("companyName", action?.payload);
      state.companyName = action.payload;
    },
    removeCompanyName: (state) => {
      localStorage?.removeItem("companyName");
      state.companyName = "";
    },
    removePrincipalName: (state) => {
      state.principalName = "";
    },
    addJwtTokens: (state, action: PayloadAction<string>) => {
      state.jwtToken = action.payload;
    },
    addRefreshTokens: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    addEmailForRegisterOnly: (state, action: PayloadAction<string>) => {
      state.emailOnlyForRegister = action.payload;
    },
    addAccountIdForRegisterOnly: (state, action: PayloadAction<string>) => {
      state.accountIdOnlyForRegister = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setIsCaptchaChecked: (state) => {
      state.isCaptchaChecked = true;
    },
    startCaptchaLoading: (state) => {
      state.isCaptchaLoading = true;
    },
    stopCaptchaLoading: (state) => {
      state.isCaptchaLoading = false;
    },
    addForgotEmailId: (state, action: PayloadAction<string>) => {
      state.forgotEmailId = action.payload;
    },
    setAppDownloadModalHideAsDoNotShowAgain: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.appDownloadModalHideAsDoNotShowAgain = action.payload;
    },
    setReOpenAppDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.reOpenAppDownloadModal = action.payload;
    },
    addEmailBeforeLogin: (state, action: PayloadAction<string>) => {
      state.emailBeforeLogin = action.payload;
    },
    setIsUserSettingPassFromEmailLink: (state) => {
      state.isUserSettingPassFromEmailLink = true;
    },
    setIsSilentLogin: (state, action) => {
      state.isSilentLogin = action.payload;
    },
    setOriginalPrimaryEmail: (state, action) => {
      if (action.payload) {
        localStorage.setItem("originalPrimaryEmail", action.payload);
      } else {
        localStorage.removeItem("originalPrimaryEmail");
      }
      state.originalPrimaryEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.acp = action?.payload?.acp?.toLowerCase();
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "user details",
        action.error?.message,
        action?.type
      );
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getSessionId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionId.fulfilled, (state, action) => {
      state.sessionId = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSessionId.rejected, (state, action) => {
      state.sessionId = "";
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  setAcpStatus,
  setAcpModalStatus,
  addToken,
  logout,
  addClientId,
  removeClientId,
  addJwtTokens,
  addRefreshTokens,
  startLoading,
  stopLoading,
  addFirstTimeSetPasswordAccountEnabled,
  addPrincipalName,
  removePrincipalName,
  addCompanyName,
  removeCompanyName,
  addChangePassClientId,
  removeChangePassClientId,
  addOTP,
  setPrefMethod,
  setCanUserResetPass,
  addAccountIdForRegisterOnly,
  addEmailForRegisterOnly,
  setContactNumber,
  setIsCaptchaChecked,
  addRootToken,
  startCaptchaLoading,
  stopCaptchaLoading,
  addForgotEmailId,
  setAppDownloadModalHideAsDoNotShowAgain,
  setReOpenAppDownloadModal,
  addEmailBeforeLogin,
  setIsUserSettingPassFromEmailLink,
  setIsSilentLogin,
  setOriginalPrimaryEmail,
} = userSlice.actions;
export default userSlice.reducer;
